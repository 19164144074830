import {
  Box,
  Divider,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NOTIFICATION_OPTIONS } from '../../backend/constants';
import CxSnippet from '../../shared/components/CxSnippet';
import {
  SELECTION_STORAGE_KEY,
  FORMDATA_STORAGE_KEY,
  visibilities,
} from '../../shared/constants';
import Features from '../../shared/helpers/Features';
import Item from '../../shared/helpers/Item';
import mode from '../../shared/helpers/Mode';
import Storage from '../../shared/helpers/Storage';
import TagManager from '../../shared/helpers/TagManager';
import {
  QUESTION_TYPES,
  MEETING_METHODS,
  FULFILLMENT_METHODS,
  DEFAULT_ATTENDEE_STATE,
} from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { LocationContext } from '../contexts/LocationContext';
import { MeetingMethodContext } from '../contexts/MeetingMethodContext';
import { ServiceContext } from '../contexts/ServiceContext';
import { useServices } from '../contexts/ServicesContext';
import { VendorContext } from '../contexts/VendorContext';
import { WaitTimeContext } from '../contexts/WaitTimeContext';
import CurrentLocation from './CurrentLocation';
import Button from './forms/Button';
import CurbsideRadio from './forms/CurbsideRadio';
import PhoneInput from './forms/PhoneInput';
import SelectInput from './forms/SelectInput';
import ServiceSelect from './forms/ServiceSelect';
import TextAreaInput from './forms/TextAreaInput';
import TextInput from './forms/TextInput';
import Policies from './Policies';
import Typography from './Typography';

const useStyles = makeStyles((theme) => ({
  formPadding: {
    padding: '1rem 2rem',
    height: '100%',
  },
  primary: {
    backgroundColor: theme.palette.primary[400],
  },
  divider: {
    width: '100%',
    margin: '2rem 0 1rem',
  },
  curbsideTitle: {
    marginTop: '1rem',
  },
  serviceSelect: {
    width: '0px',
  },
}));

const formatWaitTime = (intl, waitTime) => {
  if (!waitTime) {
    return null;
  }

  return intl.formatMessage(
    { id: 'CallbackService.topic_wait_time' },
    { waitTime },
  );
};

const formatServices = (services, locale) =>
  Item.map(services, (service) => {
    const translated = Item.find(
      service.translations,
      (translation) =>
        translation.field === 'name' && translation.locale === locale,
    );

    return {
      additional: false,
      text: Item.get(translated, 'content', service.name),
      value: service.id,
      supportsCurbsidePickup: !!service.supports_curbside_pickup,
      supportsWalkin: !!service.display_in_lobby,
    };
  });

const Form = ({ submit, submitting }) => {
  const { locale } = useContext(LocaleContext);
  const { location } = useContext(LocationContext);
  const { service, setService } = useContext(ServiceContext);
  const { meetingMethod } = useContext(MeetingMethodContext);
  const { vendor } = useContext(VendorContext);
  const { getServiceWaitTime, loading: loadingWaitTimes } =
    useContext(WaitTimeContext);
  const formDataValues =
    Storage.get(FORMDATA_STORAGE_KEY) || DEFAULT_ATTENDEE_STATE;
  const attendeeValues =
    Storage.get(SELECTION_STORAGE_KEY)?.attendee || formDataValues;

  const { services, loading: loadingServices } = useServices();
  const [topics, setTopics] = useState(formatServices(services, locale));
  const [formData, setFormData] = useReducer((state, newState) => {
    if (newState?.topic) {
      setService(
        Item.find(services, (_service) => _service.id === newState?.topic),
      );
    }
    Storage.set(FORMDATA_STORAGE_KEY, { ...state, ...newState });

    return { ...state, ...newState };
  }, attendeeValues);

  const [waitTime, setWaitTime] = useState(null);
  const [answers, setAnswers] = useState({});
  const [form, setForm] = useState({ loading: true, questions: [] });
  const [acceptedPolicies, setAcceptedPolicies] = useState(false);

  const intl = useIntl();
  const { firstName, email, lastName, notes, cellPhone, topic, receiveSms } =
    formData;
  const curbsidePickupEnabled =
    Features.isCurbsidePickupEnabled(vendor) &&
    location?.supports_curbside_pickup &&
    window.location.href.includes(
      `meeting_method=${MEETING_METHODS.AT_BUSINESS}`,
    );
  if (service?.supports_curbside_pickup && !service?.display_in_lobby) {
    formData.fulfillmentMethod = FULFILLMENT_METHODS.CURBSIDE;
  }
  if (!service?.supports_curbside_pickup && service?.display_in_lobby) {
    formData.fulfillmentMethod = FULFILLMENT_METHODS.WALK_IN;
  }

  useEffect(() => {
    setTopics(formatServices(services, locale));
  }, [locale, services]);

  useEffect(() => {
    const url = window.location.pathname;

    TagManager.trackVirtualPage(url, TagManager.pageNames.FORM, vendor, locale);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const requiresExplicitConsent = mode.isKiosk()
    ? false
    : window.state.settings.explicit_compliance_agreement;

  useEffect(() => {
    const query = topic ? `?filter[matching]=${topic}` : '';

    axios
      .get(`/api/v2/open/questions${query}`, {
        headers: { 'Accept-Language': locale },
      })
      .then(({ data: { data } }) => {
        const questions = data.map((question) => ({
          id: question.id,
          ...question.attributes,
        }));

        setAnswers(
          questions.reduce((object, question) => {
            object[question.id] = '';

            if (question.type === QUESTION_TYPES.CHECKBOX) {
              object[question.id] = question.options.reduce((obj, option) => {
                obj[option.value] = false;

                return obj;
              }, {});
            }

            return object;
          }, {}),
        );

        setForm({
          loading: false,
          questions,
        });
      });

    setWaitTime(formatWaitTime(intl, getServiceWaitTime(topic, locale)));

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getServiceWaitTime, locale, topic]);

  const setCustomAnswer = (newState) => {
    setAnswers((prevState) => ({ ...prevState, ...newState }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitting) {
      return;
    }
    const callbackRequest = {
      answers,
      ...formData,
    };
    submit(callbackRequest);
  };

  const {
    mobile_phone: phoneOption,
    email: emailOption,
    sms_notifications: smsOption,
    notes: notesOption,
  } = vendor.callback_contact_options;

  const getFieldLabel = (field) => {
    let label;
    let required;
    switch (field) {
      case 'email':
        label = <FormattedMessage id="CallbackService.email" />;
        required = emailOption === NOTIFICATION_OPTIONS.REQUIRED;
        break;
      case 'cellPhone':
        label = <FormattedMessage id="CallbackService.phone" />;
        required = phoneOption === NOTIFICATION_OPTIONS.REQUIRED;
        break;
      case 'notes':
        label = <FormattedMessage id="CallbackService.notes" />;
        required = notesOption === NOTIFICATION_OPTIONS.REQUIRED;
        break;
      default:
        return null;
    }

    return (
      <span>
        {label}
        {' ('}
        {required ? (
          <FormattedMessage id="CallbackService.required" />
        ) : (
          <FormattedMessage id="CallbackService.optional" />
        )}
        {')'}
      </span>
    );
  };

  return (
    <Paper>
      <Grid container spacing={3}>
        <Box clone order={{ xs: 2, md: 1 }}>
          <Grid item xs={12}>
            <form
              autoComplete={mode.isKiosk() ? 'off' : 'on'}
              data-testid="callback-service-form"
              onSubmit={handleSubmit}
            >
              <Grid
                alignItems="center"
                className={classes.formPadding}
                container
                direction="row"
                justify="center"
                spacing={2}
              >
                {meetingMethod === 1 ? <CurrentLocation /> : null}
                <Grid className={classes.serviceSelect} item xs={12}>
                  <ServiceSelect
                    formData={formData}
                    setFormData={setFormData}
                    topic={topic}
                    topics={topics}
                  />
                </Grid>
                {waitTime && !loadingWaitTimes ? (
                  <Grid item xs={12}>
                    <Typography>{waitTime}</Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  {curbsidePickupEnabled &&
                  service?.supports_curbside_pickup &&
                  service?.display_in_lobby &&
                  topic ? (
                    <CurbsideRadio
                      onChange={() => {
                        setFormData({
                          fulfillmentMethod:
                            formData.fulfillmentMethod ===
                            FULFILLMENT_METHODS.WALK_IN
                              ? FULFILLMENT_METHODS.CURBSIDE
                              : FULFILLMENT_METHODS.WALK_IN,
                        });
                      }}
                      value={formData.fulfillmentMethod}
                    />
                  ) : null}
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextInput
                    htmlValidation
                    id="firstName"
                    label={<FormattedMessage id="CallbackService.first_name" />}
                    name="firstName"
                    onChange={(event) => {
                      setFormData({ firstName: event.target.value });
                    }}
                    type="text"
                    value={firstName}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextInput
                    id="lastName"
                    label={<FormattedMessage id="CallbackService.last_name" />}
                    name="lastName"
                    onChange={(event) => {
                      setFormData({ lastName: event.target.value });
                    }}
                    type="text"
                    value={lastName}
                  />
                </Grid>
                {emailOption !== NOTIFICATION_OPTIONS.HIDDEN ? (
                  <Grid item xs={12}>
                    <TextInput
                      id="email"
                      label={getFieldLabel('email')}
                      name="email"
                      onChange={(event) => {
                        setFormData({
                          email: event.target.value.replace(/ /g, ''),
                        });
                      }}
                      required={emailOption === NOTIFICATION_OPTIONS.REQUIRED}
                      type="email"
                      value={email}
                    />
                    <CxSnippet targetId="email_disclosure" />
                  </Grid>
                ) : null}
                {phoneOption !== NOTIFICATION_OPTIONS.HIDDEN ? (
                  <Grid item xs={12}>
                    <PhoneInput
                      country={location.country}
                      errors={[]}
                      id="cellPhone"
                      label={getFieldLabel('cellPhone')}
                      name="cellPhone"
                      onChange={setFormData}
                      required={phoneOption === NOTIFICATION_OPTIONS.REQUIRED}
                      value={cellPhone}
                    />
                    {smsOption === NOTIFICATION_OPTIONS.OPTIONAL ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={receiveSms}
                            color="default"
                            name="receive-sms"
                            onChange={() => {
                              setFormData({ receiveSms: !receiveSms });
                            }}
                          />
                        }
                        label={
                          <Typography variant="regular">
                            <CxSnippet
                              fallback={
                                <FormattedMessage id="CallbackService.sms_opt_in" />
                              }
                              targetId="sms_opt_in_disclosure"
                            />
                          </Typography>
                        }
                      />
                    ) : (
                      <CxSnippet
                        fallback={
                          <FormattedMessage id="CallbackService.sms_required" />
                        }
                        targetId="sms_required_disclosure"
                      />
                    )}
                  </Grid>
                ) : (
                  <CxSnippet targetId="sms_not_sent_disclosure" />
                )}

                {formData.fulfillmentMethod === FULFILLMENT_METHODS.CURBSIDE ? (
                  <>
                    <Grid item xs={12}>
                      <div className={classes.curbsideTitle}>
                        <Typography component="h2" variant="h6">
                          <FormattedMessage id="CallbackService.curbside.radio.pickup_information" />
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.curbsideSubtitle}>
                        <div className={classes.textInput}>
                          <TextAreaInput
                            id="carDetails"
                            label={
                              <FormattedMessage id="CallbackService.curbside.radio.car_details" />
                            }
                            name="carDetails"
                            onChange={(e) => {
                              setFormData({ carDetails: e.target.value });
                            }}
                            required
                            rows="4"
                            value={formData.carDetails}
                          />
                        </div>
                      </div>
                    </Grid>
                  </>
                ) : null}

                {form.loading === false &&
                form.questions.length > 0 &&
                vendor.walkin_questions
                  ? form.questions.map((question) => {
                      const key = `questions.${question.id}`;
                      const props = {
                        helpText: question.placeholder,
                        id: key,
                        label: question.label,
                        name: key,
                        onChange: (event) =>
                          setCustomAnswer({
                            [question.id]: event.target.value,
                          }),
                        optional: !question.required,
                        required: question.required,
                        value: answers[question.id],
                      };

                      if (
                        question.type === QUESTION_TYPES.CHECKBOX ||
                        question.type === QUESTION_TYPES.RADIO
                      ) {
                        return null;
                      }

                      return (
                        <Grid item key={question.id} xs={12}>
                          {question.type === QUESTION_TYPES.TEXT && (
                            <TextInput {...props} />
                          )}
                          {question.type === QUESTION_TYPES.SELECT && (
                            <SelectInput
                              {...props}
                              options={question.options}
                            />
                          )}
                          {question.type === QUESTION_TYPES.TEXTAREA && (
                            <TextAreaInput {...props} />
                          )}
                        </Grid>
                      );
                    })
                  : null}

                {notesOption !== NOTIFICATION_OPTIONS.HIDDEN ? (
                  <>
                    <Divider className={classes.divider} />
                    <Grid item xs={12}>
                      <TextAreaInput
                        data-testid="notes"
                        id="notes"
                        label={getFieldLabel('notes')}
                        name="notes"
                        onChange={(event) => {
                          setFormData({ notes: event.target.value });
                        }}
                        required={notesOption === NOTIFICATION_OPTIONS.REQUIRED}
                        value={notes}
                      />
                    </Grid>
                  </>
                ) : null}
                <Policies
                  accepted={acceptedPolicies}
                  setAccepted={setAcceptedPolicies}
                />
                <Grid item xs={12}>
                  <Button
                    data-testid="submit-callback-request"
                    disabled={
                      submitting ||
                      loadingServices ||
                      !services.length ||
                      location.visibility === visibilities.PRIVATE ||
                      (requiresExplicitConsent && !acceptedPolicies)
                    }
                    type="submit"
                  >
                    <FormattedMessage id="CallbackService.submit_form" />
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
};

Form.propTypes = {
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default Form;
